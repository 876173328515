<template>
  <div class="container has-text-centered my-6 px-5">
    <h1 class="title is-size-5-mobile">Update your new password.</h1>
    <ResetPasswordForm @submit="submit" />
  </div>
</template>
<script>
import ResetPasswordForm from "@/components/forms/ResetPasswordForm.vue";
export default {
  components: { ResetPasswordForm },
  data() {
    return {
      loadingSubmit: false,
    };
  },
  methods: {
    async submit(formData) {
      this.loadingSubmit = true;
      await this.$store.dispatch("user/resetPassword", {
        token: this.$route.params.token,
        password: formData.password,
      });
      this.loadingSubmit = false;
      this.$buefy.toast.open({
        message: "Password update successfully!",
        type: "is-success",
      });
      this.$router.push("/login");
    },
  },
  provide() {
    return {
      isLoadingSubmitButton: () => this.loadingSubmit,
    };
  },
};
</script>
