<template>
  <div class="container has-text-left">
    <div class="block">
      <b-field
        :type="{ 'is-danger': $v.formData['password'].$error }"
        label="Password"
      >
        <b-input
          v-on:keyup.enter.native="onSubmit"
          v-model="formData.password"
          type="passwords"
          required
        >
        </b-input>
      </b-field>
    </div>
    <!-- <div class="block">
      <b-field
        :type="{ 'is-danger': $v.formData['passwordConfirmation'].$error }"
        label="Password Confirmation"
      >
        <b-input
          v-on:keyup.enter.native="onSubmit"
          v-model="formData.passwordConfirmation"
          type="email"
          required
        >
        </b-input>
      </b-field>
    </div> -->
    <div class="actions">
      <p class="control">
        <b-button
          expanded
          tag="button"
          native-type="submit"
          :loading="isLoadingSubmitButton() == true"
          class="button is-primary"
          @click="onSubmit"
        >
          Update
        </b-button>
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.actions {
  width: fit-content;
  margin: auto;
}
.container {
  max-width: 600px;
}
.text-white .label {
  color:black;
}
</style>
<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  inject: ["isLoadingSubmitButton"],
  data() {
    return {
      formData: {
        password: null,
      },
    };
  },
  validations: {
    formData: {
      password: {
        required,
        minLength: minLength(6),
      },
      // passwordConfirmation: {
      //   required,
      //   minLength: minLength(6),
      // },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("submit", this.formData);
      }
    },
  },
};
</script>
